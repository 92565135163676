import { Component } from '@angular/core';
import { RoutesService } from './routes/routes.service';
import { I18nService } from './i18n/i18n.service';
import { TranslocoService } from '@jsverse/transloco';
import { IconSvgService } from './components/icon-svg/icon-svg.service';
import { AppDeviceTypeService } from './app.device-type.service';
import { ActivationEnd, Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	currentDevice;
	path = '';

	constructor(
		private routesService: RoutesService,
		private i18nService: I18nService,
		private translocoService: TranslocoService,
		private appDeviceTypeService: AppDeviceTypeService,
		private iconSvgService: IconSvgService,
		private router: Router
	) {

		console.log(AppComponent.name, routesService.getRoutes());
		console.log(AppComponent.name, i18nService.detectedLocale);
		// console.log(this.translocoService.translate('title'));

		this.setCurrentDevice();

		this.router.events.subscribe(event => {
			if(event instanceof ActivationEnd){
				this.path = event.snapshot.data.basePath;
			}
		});

	}

	setCurrentDevice = () =>
		this.currentDevice = this.appDeviceTypeService.device;

	getRoute = name => this.routesService.getRoute(name);

}
