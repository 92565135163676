import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { APIPortalAuthTokenQrcodeService } from 'src/app/api-services/portal/auth-token-qrcode.service';
import { appRoles, AuthRolesService } from 'src/app/auth/auth-roles.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateLoadService } from 'src/app/i18n/translate-load.service';
import { RouteGuard } from '../routes.guard';
import { RouteData } from '../routes.interface';
import { RoutesService } from '../routes.service';
import { NformNonuserService } from './nform-nonuser.service';
import { lastValueFrom } from 'rxjs';

const routeConfig = {
	nformNonUser: {
		path: 'answer-nform',
		label: 'Route.Dashboard.Label'
	}
};

@Injectable({
	providedIn: 'root',
})
export class NformNonuserRouteGuard  {

	constructor(
		// private authService: AuthService,
		private authService: AuthService,
		private nformNonuserService: NformNonuserService,
		private authRolesService: AuthRolesService,
		private apiPortalAuthTokenQrcodeService: APIPortalAuthTokenQrcodeService,
		private translateLoadService: TranslateLoadService
	){

	}

	private requestAuthTokenFromQRcode(queryStringQrCode: string): Promise<boolean>{

		return new Promise((resolve, reject) => {
			lastValueFrom(
				this.apiPortalAuthTokenQrcodeService.get({
					headers: {
						nform: {
							P_BARCODE_ENC: queryStringQrCode,
							P_PROCESS: new URLSearchParams(window.location.search).get('p')
						}
					}
				})
			)
				.then(
					response => {
						this.authService.setAuthWithTokenInfoAndCustomerOBJ(response.body)
							.then(() => resolve(true));
					},
					error => {
						this.nformNonuserService.qrcodeError = error;
						this.setQRCode('invalid');
						resolve(true);
					},
				);
		});

	}

	private setQRCode(qrcode: string, process: string = null){

		console.log('routeguard nform setQRcode: ', qrcode, process);

		this.nformNonuserService.currentPath = routeConfig.nformNonUser.path;

		if(qrcode){
			this.nformNonuserService.qrcode = qrcode;
			this.nformNonuserService.process = process;
			this.nformNonuserService.doNotRequestLogin = preventRequestLogin();
		}else if(!this.nformNonuserService.qrcode){
			this.nformNonuserService.qrcode = 'invalid';
		}

		return true;

	}

	canMatch(){
		return this.canLoad();
	}

	// eslint-disable-next-line max-lines-per-function
	// eslint-disable-next-line max-statements
	canLoad(): boolean | Promise<boolean>{

		this.authService.deleteTokenFromStorage();

		if(!this.authRolesService.getRole() && this.authService.isLogged()){
			this.authService.logout(false);
		}

		const queryStringQrCode = new URLSearchParams(window.location.search).get('b');
		const queryStringProcess = new URLSearchParams(window.location.search).get('p');
		const result = this.setQRCode(queryStringQrCode, queryStringProcess);

		this.nformNonuserService.qrcodeError = null;

		if(!queryStringQrCode){
			this.authService.loginRoutePathParams = {
				appRole: appRoles.nformNonuser,
				queryParams: {
					b: 'invalid',
					s: 'invalid'
				}
			};
			this.authService.logout(true);
			return false;
		}

		if(!preventRequestLogin() && !this.authService.isLogged()){

			const queryParams = {};
			const redirQueryParams = new URLSearchParams(window.location.search);
			redirQueryParams.forEach((v, k) => queryParams[k] = v);

			this.authService.loginRoutePathParams = {
				appRole: appRoles.nformNonuser,
				queryParams
			};

			return false;

		}

		if(preventRequestLogin()){
			return this.requestAuthTokenFromQRcode(queryStringQrCode);
		}

		return result;

	}

	canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot){

		if(this.authService.isLogged() && !activatedRouteSnapshot.params.tab){
			this.authService.logout(true);
			return false;
		}

		return true;
	}

}

export const preventRequestLogin = () => {
	const queryStringLogin = new URLSearchParams(window.location.search).get('l');
	return queryStringLogin === '0';
};

const routeData: RouteData = {
	preventRequestLogin,
	appRole: appRoles.nformNonuser,
	basePath: routeConfig.nformNonUser.path,
	keepAfterLoginRedir: false
};

@NgModule({
	imports: [RouterModule.forRoot([
		{
			path: routeConfig.nformNonUser.path,
			redirectTo: routeConfig.nformNonUser.path + '/0',
		},
		{
			path: routeConfig.nformNonUser.path + '/:tab',
			data: routeData,
			canMatch: [NformNonuserRouteGuard, RouteGuard],
			canActivate: [NformNonuserRouteGuard, RouteGuard],
			loadChildren: () => import('./nform-nonuser.module')
				.then(m => m.NformNonuserModule)
		}
	])],
	exports: [RouterModule]
})
export class NformNonuserRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

