import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { UserPermissions } from 'src/app/auth/auth.interface';
import { RouteGuard } from '../routes.guard';
import { RouteData } from '../routes.interface';
import { RoutesService } from '../routes.service';

const routeConfig = {
	newTicket: {
		path: 'new-ticket',
		label: 'Route.NewTicket.Label'
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.newTicket.path,
	keepAfterLoginRedir: true,
	navigationBar: false,
	permissionKey: UserPermissions.routeNewTicket
};

const routes: Routes = [
	{
		path: routeConfig.newTicket.path,
		redirectTo: routeConfig.newTicket.path + '/0',
		pathMatch: 'full'
	},
	{
		path: routeConfig.newTicket.path + '/:tab',
		data: routeData,
		canMatch: [RouteGuard],
		canActivate: [RouteGuard],
		loadChildren: () => import('./new-ticket.module').then(m => m.NewTicketModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class NewTicketRoutingModule {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}
